<template>
  <div class='flex flex-row justify-start items-start mb-6'>
    <label class='w-64 text-gray-500 flex-shrink-0 flex-grow-0 pt-3'>Presentation Slides Upload</label>
    <div class='flex flex-col justify-start items-start gap-y-2'>
      <el-upload v-if='!uploadedPresentationFilename || showUploader'
        action=''
        :auto-upload='false'
        :on-change='handleFileUpload'
        :on-remove='handleFileRemove'
        :on-exceed='handleFileExceed'
        :multiple='false'
        :file-list='presentationFileList'
        accept='.ppt,.pptx,.pdf'>
        <div 
          class='w-64 py-2 border rounded-md text-white hover:shadow-md flex flex-row justify-center items-center gap-x-2'
          :style='eventMainThemeColorBg'>
          <i class='el-icon-upload text-xl'></i>
          <div class='text-sm uppercase'>Upload Presentation</div>
        </div>
      </el-upload>
      <div v-else class='pt-3 truncate max-w-lg'>
        <div>{{uploadedPresentationFilename}}</div>
        <button 
          class='uppercase py-0.5 px-2 border border-gray-300 rounded hover:shadow hover:border-gray-500'
          @click='showUploader=true'>re-upload</button>
      </div>
      <sub class='block py-1 uppercase'>
        <span class='text-red-500 font-bold inline-block pr-1'>*</span>
        <span class='text-gray-500 pr-1'>Deadline to upload:</span>
        <span class='text-gray-900 font-semibold'>September 30, 2022</span>
      </sub>
      <sub class='block py-1 uppercase'>
        <span class='text-red-500 font-bold inline-block pr-1'>*</span>
        <span class='text-gray-500 pr-1'>Accepted File Types:</span>
        <span class='text-gray-900 font-semibold'>PPT, PPTX, PDF</span>
      </sub>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AbstractMaterialUploadOralPresentation',
  props: [
    'abstract'
  ],
  data () {
    return {
      oralPresentation: {},
      showUploader: false,
    }
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
      'showingEventName',
      'eventMainThemeColorBg',
    ]),
    presentationFileList () {
      return this.abstract.oralPresentationFileUrl && !this.showUploader ? [this.abstract.oralPresentationFileUrl] : []
    },
    uploadedPresentationFilename () {
      if (this.abstract.oralPresentationFileUrl) {
        let filename = this.abstract.oralPresentationFileUrl.split('/').pop()
        return decodeURIComponent(filename)
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'updateOralPresentationFile',
    ]),
    handleFileUpload(file) {
      this.oralPresentation = { file: file.raw, name: file.name }
      let data = new FormData()
      data.append('oral_presentation', file.raw, file.name)
      this.updateOralPresentationFile({ abstractId: this.abstract.id, formData: data }).then(() => {
        this.showUploader = false
        this.$alert('Oral Presentation File upload completed', this.showingEventName, {
          type: 'success',
          confirmButtonText: 'OK'
        })
      })
    },
    handleFileRemove () {
      this.$message.warning('Please contact the administrator to remove the file.')
    },
    handleFileExceed () {
      this.$message.warning('최대 1개의 Oral Presentation File를 업로드 할 수 있습니다.')
    },
  },
}
</script>


