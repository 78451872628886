<template>
  <div class='w-full text-sm'>
    <div class='flex flex-row justify-start items-center mb-6'>
      <label class='w-64 text-gray-500 flex-shrink-0 flex-grow-0'>Submission Status</label>
      <div v-if='submissionStatusComplete'
        class='text-green-900 uppercase rounded flex-grow-0 px-2 py-0.5 border border-green-400 bg-green-300'>Complete
      </div>
      <div v-else class='text-red-900 uppercase rounded flex-grow-0 px-2 py-0.5 border border-red-400 bg-red-300'>
        Incomplete</div>

    </div>
    <div class='flex flex-row justify-start items-start mb-6'>
      <label class='w-64 text-gray-500 flex-shrink-0 flex-grow-0'>Accepted Presentation Type</label>
      <div class='text-gray-900 flex-grow'>{{ abstract.acceptedPresentationType }}</div>
    </div>
    <div class='flex flex-row justify-start items-start mb-6'>
      <label class='w-64 text-gray-500 flex-shrink-0 flex-grow-0'>Accepted Subtopic</label>
      <div class='text-gray-900 flex-grow'>{{ abstract.acceptedSubtopic }}</div>
    </div>
    <component v-for='uploadComponent in requiredUploadFiles'
      :key='`upload-for-${abstract.id}-${uploadComponent}`'
      :is='uploadComponent'
      :abstract='abstract' />
  </div>
</template>

<script>
import AbstractMaterialUploadVideo                from '@/components/abstracts/AbstractMaterialUploadVideo.vue'
import AbstractMaterialUploadOralExtendedAbstract from '@/components/abstracts/AbstractMaterialUploadOralExtendedAbstract.vue'
import AbstractMaterialUploadOralPresentation     from '@/components/abstracts/AbstractMaterialUploadOralPresentation.vue'
import AbstractMaterialUploadPoster               from '@/components/abstracts/AbstractMaterialUploadPoster.vue'

export default {
  name: 'AbstractMaterialUploadPerAbstract',
  components: {
    AbstractMaterialUploadVideo,
    AbstractMaterialUploadOralExtendedAbstract,
    AbstractMaterialUploadOralPresentation,
    AbstractMaterialUploadPoster,
  },
  props: [
    'abstract'
  ],
  data () {
    return {
    }
  },
  computed: {
    isVideoPresentation () {
      return this.abstract.acceptedPresentationType === 'Video'
    },
    isPosterPresentation () {
      return this.abstract.acceptedPresentationType === 'Poster'
    },
    isOralPresentation () {
      return this.abstract.acceptedPresentationType === 'Oral'
    },
    submissionStatusComplete () {
      if (this.isVideoPresentation) {
        return this.abstract.vimeoVideoId
      } else if (this.isPosterPresentation) {
        return this.abstract.posterPresentationFileUrl
      } else if (this.isOralPresentation) {
        return this.abstract.oralExtendedAbstractFileUrl && this.abstract.oralPresentationFileUrl
      } else {
        return false
      }
    },
    requiredUploadFiles () {
      if (this.isVideoPresentation) {
        return ['abstract-material-upload-video']
      } else if (this.isPosterPresentation) {
        return ['abstract-material-upload-poster']
      } else if (this.isOralPresentation) {
        return ['abstract-material-upload-oral-extended-abstract', 'abstract-material-upload-oral-presentation']
      } else {
        return []
      }
    }
  },
  methods: {

  },
  mounted () {
  },
}
</script>


