<template>
  <div class='relative py-8 lg:py-16'>
    <content-background-cover background-color='#FAFAFA' class='z-0' />
    <h1 class='text-2xl font-semibold text-gray-900'>Upload Abstract Files</h1>
    <div v-if='!readyForMaterialUploads' class='rounded-md bg-gray-100 py-16 px-4 my-8 text-center text-gray-600'>
      Please check back here on July 3rd, 2022 to upload your abstract materials.
    </div>
    <div v-else-if='readyForMaterialUploads && !materialUploadsDeadlineHasPassed'>
      <h2 class='text-lg font-normal uppercase text-gray-700'>
        Accepted Abstract Submissions ({{myAbstracts.length}})
      </h2>
      <div v-for='abstract in myAbstracts' :key='`my-abstract-${abstract.id}`'
        class='flex flex-row justify-between items-start border-b pb-8 lg:pb-16 my-8 lg:my-16 gap-x-16'>
        <abstract-submission-preview :abstract='abstract' class='overflow-y-auto border rounded-md max-w-xl opacity-70 flex-shrink-0'
          style='height: 400px;' />
        <abstract-material-upload-per-abstract :abstract='abstract' class='w-full text-sm' />
      </div>
      <div v-if='!hasAbstracts' class='rounded-md bg-gray-100 py-8 px-4 my-8 text-center text-gray-600'>
        <p class='mb-2'>You have not yet submitted any abstracts.</p>
      </div>
    </div>
    <div v-else class='rounded-md bg-gray-100 py-16 px-4 my-8 text-center text-gray-600'>
      Abstract submission file uploads deadline has passed.
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover            from '@/components/ContentBackgroundCover.vue'
import AbstractSubmissionPreview         from '@/components/abstracts/AbstractSubmissionPreview.vue'
import AbstractMaterialUploadPerAbstract from '@/components/abstracts/AbstractMaterialUploadPerAbstract.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)


export default {
  name: 'AbstractMaterialUpload',
  components: {
    ContentBackgroundCover,
    AbstractSubmissionPreview,
    AbstractMaterialUploadPerAbstract,
  },
  computed: {
    ...mapState('abstracts', [
      'myAbstracts',
    ]),
    ...mapGetters('events', [
      'showingEventId',
    ]),
    hasAbstracts() {
      return this.myAbstracts.length > 0
    },
    readyForMaterialUploads () {
      return dayjs().isSameOrAfter(dayjs('2022-07-03'))
    },
    materialUploadsDeadlineHasPassed () {
      return dayjs().isSameOrAfter(dayjs('2022-12-25 00:00'))
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'getMyAcceptedAbstracts',
    ]),
  },
  mounted () {
    this.getMyAcceptedAbstracts(this.showingEventId)
  },
}
</script>


