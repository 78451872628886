<template>
  <div class='flex flex-row justify-start items-start mb-6'>
    <label class='w-64 text-gray-500 flex-shrink-0 flex-grow-0 pt-3'>Extended Abstract Upload</label>
    <div class='flex flex-col justify-start items-start gap-y-2'>
      <el-upload v-if='!uploadedPosterFilename'
        action=''
        :auto-upload='false'
        :on-change='handleFileUpload'
        :on-remove='handleFileRemove'
        :on-exceed='handleFileExceed'
        :multiple='false'
        :file-list='extendedAbstractFileList'
        accept='.doc,.docx,.pdf'>
        <div 
          class='w-64 py-2 border rounded-md text-white hover:shadow-md flex flex-row justify-center items-center gap-x-2'
          :style='eventMainThemeColorBg'>
          <i class='el-icon-upload text-xl'></i>
          <div class='text-sm uppercase'>Upload Extended Abstract</div>
        </div>
      </el-upload>
      <div v-else class='pt-3'>{{uploadedPosterFilename}}</div>
      <sub class='block py-1 uppercase'>
        <span class='text-red-500 font-bold inline-block pr-1'>*</span>
        <span class='text-gray-500 pr-1'>Deadline to upload:</span>
        <span class='text-gray-900 font-semibold'>August 12, 2022</span>
      </sub>
      <sub class='block py-1 uppercase'>
        <span class='text-red-500 font-bold inline-block pr-1'>*</span>
        <span class='text-gray-500 pr-1'>Accepted File Types:</span>
        <span class='text-gray-900 font-semibold'>Doc, Docx, PDF</span>
      </sub>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AbstractMaterialUploadOralExtendedAbstract',
  props: [
    'abstract'
  ],
  data () {
    return {
      oralExtendedAbstract: {},
    }
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
      'showingEventName',
      'eventMainThemeColorBg',
    ]),
    extendedAbstractFileList () {
      return this.abstract.oralExtendedAbstractFileUrl ? [this.abstract.oralExtendedAbstractFileUrl] : []
    },
    uploadedPosterFilename() {
      if (this.abstract.oralExtendedAbstractFileUrl) {
        let filename = this.abstract.oralExtendedAbstractFileUrl.split('/').pop()
        return decodeURIComponent(filename)
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'updateOralExtendedAbstractFile',
    ]),
    handleFileUpload (file) {
      this.oralExtendedAbstract = { file: file.raw, name: file.name }
      let data = new FormData()
      data.append('oral_extended_abstract', file.raw, file.name)
      this.updateOralExtendedAbstractFile({ abstractId: this.abstract.id, formData: data }).then(() => {
        this.$alert('Poster upload completed', this.showingEventName, {
          type: 'success',
          confirmButtonText: 'OK'
        })
      })
    },
    handleFileRemove () {
      this.$message.warning('Please contact the administrator to remove the file.')
    },
    handleFileExceed () {
      this.$message.warning('최대 1개의 Oral Exteneded Abstract를 업로드 할 수 있습니다.')
    },
  },
  mounted () {
  },
}
</script>


