<template>
  <div class='flex flex-row justify-start items-start mb-6'>
    <label class='w-64 text-gray-500 flex-shrink-0 flex-grow-0'>Video Upload</label>
    <div class='flex flex-col justify-start items-start gap-y-2'>
      <el-upload
        action=''
        :auto-upload='false'
        :on-preview='openPlayer'
        :on-change='handleFileUpload'
        :on-remove='handleFileRemove'
        :on-exceed='handleFileExceed'
        :multiple='false'
        :file-list='videoFileList'
        accept='video/*'>
        <div
          class='w-64 py-2 border rounded-md text-white hover:shadow-md flex flex-row justify-center items-center gap-x-2'
          :style='eventMainThemeColorBg'>
          <i class='text-2xl' :class='uploadBoxIcon'></i>
          <div class='text-sm uppercase'>{{uploadBoxText}}</div>
        </div>
      </el-upload>
      <sub class='block py-1 uppercase'>
        <span class='text-red-500 font-bold inline-block pr-1'>*</span>
        <span class='text-gray-500 pr-1'>Deadline to upload:</span>
        <span class='text-gray-900 font-semibold'>September 30, 2022</span>
      </sub>
      <sub class='block py-1 uppercase'>
        <span class='text-red-500 font-bold inline-block pr-1'>*</span>
        <span class='text-gray-500 pr-1'>Accepted Video Files:</span>
        <span class='text-gray-900 font-semibold'>MP4, AVI, MOV</span>
      </sub>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import abstractsApi from '@/api/v1/abstracts'
import * as tus from 'tus-js-client'
import axios from 'axios'

export default {
  name: 'AbstractMaterialUploadVideo',
  components: {

  },
  props: [
    'abstract'
  ],
  data () {
    return {
      video: null,
      currentUploadLink: '',
      progress: 0,
      uploading: false,
      requestedUploadUrl: false
    }
  },
  watch: {
    video: {
      handler: function (newVal, oldVal) {
        if ((newVal && newVal !== oldVal) || !newVal) {
          this.progress = 0
          this.requestedUploadUrl = false
        }
      }
    },
    progress: {
      handler: function (newVal) {
        if (newVal == 100.0) {
          this.uploading = false
        }
      },
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
      'showingEventName',
      'eventMainThemeColorBg',
    ]),
    videoFileList () {
      return this.abstract.vimeoVideoId ? [{name: this.abstract.vimeoVideoId, url: `https://player.vimeo.com/video/${this.abstract.vimeoVideoId}?h=0708cf4566`}] : []
    },
    uploadBoxIcon () {
      if (this.progress === 0 && !this.uploading) {
        return 'el-icon-upload'
      } else if (this.progress < 0 && this.requestedUploadUrl) {
        return 'el-icon-loading'
      } else if (this.progress < 100 && this.uploading) {
        return 'el-icon-loading'
      } else if (this.progress == 100 && !this.uploading) {
        return 'el-icon-circle-check'
      } else {
        return ''
      }
    },
    uploadBoxText () {
      if (this.progress === 0 && !this.uploading) {
        return 'click to upload video'
      } else if (this.progress < 0 && this.requestedUploadUrl) {
        return `${this.progress}%`
      } else if (this.progress < 100 && this.uploading) {
        return `${this.progress}%`
      } else if (this.progress == 100 && !this.uploading) {
        return 'Done'
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapMutations('abstracts', [
      'updateMyAbstracts',
    ]),
    checkUpload () {
      if (this.currentUploadLink) {
        return axios.head(
          this.currentUploadLink,
          {
            headers: {
              'Tus-Resumable': '1.0.0',
              'Accept': 'application/vnd.vimeo.*+json;version=3.4'
            }
          }
        ).then(resp => {
          let currentByte = parseInt(resp.headers['upload-offset'])
          let totalByte = parseInt(resp.headers['upload-length'])
          this.progress = currentByte * 100.0 / totalByte
        })
      }
    },
    openPlayer (file) {
      window.open(file.url, '_blank').focus()
    },
    startCheckUpload () {
      if (this.uploading && this.progress < 100.0) {
        this.checkUpload().then(() => setTimeout(this.startCheckUpload, 5000))
      }
    },
    startUpload (file) {
      if (file) {
        this.requestedUploadUrl = true
        abstractsApi.postVideoUpload(
          this.abstract.id,
          { video_upload: 
            {
              event_id: this.showingEventId,
              original_filename: file.name,
              size_bytes: file.size,
              name: `event(${this.showingEventId})-abstract-${this.abstract.abstractNo}`,
              description: `${this.showingEventId}-${file.name}`
            }
          }).then(resp => {
            this.currentUploadLink = resp.vimeo_upload_link
            let upload = new tus.Upload(file.raw, {
              uploadUrl: resp.vimeo_upload_link,
              onError: (error) => {
                console.error("Failed because: " + error)
                this.$message.warning("Failed because: " + error)
              },
              onSuccess: () => {
                this.updateMyAbstracts(Object.assign(this.abstract, {vimeoVideoId: resp.vimeo_video_id, videoOriginalFilename: file.name}))
                console.log("Download %s from %s", upload.file.name, upload.url)
                this.$alert('Video upload completed. Allow video to transcode before playing.', this.showingEventName, {
                  type: 'success',
                  confirmButtonText: 'OK' })
              }
          })
          upload.start()
          this.uploading = true
          this.startCheckUpload()
        }).catch(err => {
          if (err.response) {
            console.error(err.response.data)
          }
        })
      }
    },
    handleFileUpload (file) {
      this.video = file
      this.startUpload(file)
    },
    handleFileRemove () {
      this.$message.warning('Please contact the administrator to remove the file.')
    },
    handleFileExceed () {
      this.$message.warning('최대 1개의 Poster를 업로드 할 수 있습니다.')
    },
  },
}
</script>


